import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { Typography, Button } from "@material-ui/core";
import "./packingScreen.scss";
import PackingScreenModalStatusBadge from "./PackingScreenModalStatusBadge";

const PackingScreenModalChunkUpcomingOrders = (props) => {
  const { className, ordersInChunk, orderIndex, setOrderIndex } = props;
  const selectedOrder = useRef(null);

  useEffect(() => {
    if (selectedOrder.current) {
      selectedOrder.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  }, [selectedOrder.current]);

  return (
    <div className={`packing-screen-section-container ${className ?? ""}`}>
      <Typography variant="h5">Upcoming Orders</Typography>
      <div className="packing-screen-list">
        {ordersInChunk.map((order, index) => {
          return (
            <div
              key={order.id}
              ref={index === orderIndex ? selectedOrder : null}
              style={{
                border: index === orderIndex ? "1px solid black" : "",
              }}
              className="packing-screen-list-item"
              onClick={() => setOrderIndex(index)}
            >
              <div className="packing-screen-list-item-title">
                <Typography variant="subtitle1">{`${order.customerFirstName} ${order.customerLastName}`}</Typography>
                <PackingScreenModalStatusBadge
                  orderId={order.id}
                  size="small"
                  status={order.status}
                />
              </div>
              <div className="packing-screen-captioned-grid">
                {order.courierOrder.quantitiesDescription
                  .split(" | ")
                  .map((item) => (
                    <div>{item}</div>
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PackingScreenModalChunkUpcomingOrders;
