import React from "react";
import { Button, Typography } from "@material-ui/core";
import Modal from "../Modal/Modal";
import * as styles from "./DeleteModal.module.css";

const DeleteModal = ({ open, message, handleClose, handleSubmit }) => {
  return (
    <Modal visible={open} close={handleClose}>
      <div className={styles.confirmContainer}>
        <Typography variant="h4">Are you sure?</Typography>
        <Typography>
          {message || "This action will permanently remove this item."}
        </Typography>
        <div className={styles.actionContainer}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
