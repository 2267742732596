import React from "react";
import _ from "lodash";
import { Typography, Button, Chip } from "@material-ui/core";
import { Description as DescriptionIcon } from "@material-ui/icons";
import "./packingScreen.scss";
import PackingScreenModalStatusBadge from "./PackingScreenModalStatusBadge";
import { connect } from "react-redux";
import { bulkCompleteOrders, bulkLabels } from "../../reduxActions/order";

const PackingScreenModalCurrentOrder = (props) => {
  const { className, order, bulkCompleteOrders, onCompleteOrder, bulkLabels } =
    props;
  console.log(order);

  const addressString = `${
    order.shippingAddress.companyName
      ? `${order.shippingAddress.companyName} - `
      : ""
  }${order.shippingAddress.address1 ?? ""}, ${
    order.shippingAddress.address2 ? `${order.shippingAddress.address2},` : ""
  } ${order.shippingAddress.city} ${order.shippingAddress.zip}`;

  const boxSizes = { 6: 0, 12: 0, 18: 0, 24: 0, 36: 0 };
  order.boxSize.split(",").forEach((boxSize) => {
    if (parseInt(boxSize) <= 6) {
      boxSizes[6]++;
    } else if (parseInt(boxSize) <= 12) {
      boxSizes[12]++;
    } else if (parseInt(boxSize) <= 18) {
      boxSizes[18]++;
    } else if (parseInt(boxSize) <= 24) {
      boxSizes[24]++;
    } else if (parseInt(boxSize) <= 36) {
      boxSizes[36]++;
    }
  });
  const boxSizeString =
    Object.entries(boxSizes)
      .filter(([_, count]) => count > 0)
      .map(([size, count]) => `${count} x ${size}0`)
      .join(", ") + " Box";

  return (
    <div className={`packing-screen-section-container ${className ?? ""}`}>
      <div className="packing-screen-section-section">
        <div className="packing-screen-current-order-title">
          <Typography variant="h5">Currently Packing</Typography>
          <div className="packing-screen-current-order-title-buttons">
            <PackingScreenModalStatusBadge
              orderId={order.id}
              status={order.status}
            />
            <Button variant="contained" onClick={() => bulkLabels([order.id])}>
              Print Label
            </Button>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Chip
            label={`SC${order.orderNumber}`}
            size="small"
            variant="outlined"
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <Chip label={boxSizeString} size="small" variant="outlined" />
            <Chip
              label={
                order.courierOrder.courier === 1
                  ? "NZPost"
                  : order.courierOrder.courier === 2
                  ? "Aramex"
                  : "Express"
              }
              size="small"
              variant="outlined"
            />
          </div>
        </div>
        <Typography variant="h6">{`${order.customerFirstName} ${order.customerLastName}`}</Typography>
        <Typography variant="span">{addressString}</Typography>
      </div>
      <div className="packing-screen-captioned-grid-big">
        <Typography variant="span">Order Items</Typography>
        <div className="packing-screen-captioned-grid-big-inner">
          {order.courierOrder.quantitiesDescription.split(" | ").map((item) => (
            <div className="packing-screen-captioned-grid-big-item">{item}</div>
          ))}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          bulkCompleteOrders([order.id]);
          onCompleteOrder();
        }}
      >
        Mark Order As Complete
      </Button>
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { bulkCompleteOrders, bulkLabels })(
  PackingScreenModalCurrentOrder
);
